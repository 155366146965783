// Homepage hover
if($('body').hasClass('home')){
  $( ".element" ).hover(
    function() {
      $( this ).addClass( "hover" );
      $('.element').addClass( "non-hover" );
      $( this ).removeClass( "non-hover" );
    }, function() {
      $( this ).removeClass( "hover" );
      $('.element').removeClass( "non-hover" );
    }
  );
}


$(function() {
  $(".project").hover(
      function() {
          var project_gif = $(this).attr("data-gif");
          var $this = $(this);

          // Set a timeout for the hover-in effect
          setTimeout(function() {
              $this.css({'background-image': "unset"});
              $this.css({'background-color': "#928C81"});
          }, 500);
      },
      function() {
          var project_image = $(this).attr("data-image");
          var $this = $(this);

          // Set a timeout for the hover-out effect
          setTimeout(function() {
              $this.css({'background-color': "unset"});
              $this.css({'background-image': "url('" + project_image + "')"});
          }, 500);
      }
  );
});


/*scroll header*/
$(window).scroll(function() {
  if ($(document).scrollTop() > 700) {
      $('.header_wrapper').addClass('scrolled');
  }
  else{
    $('.header_wrapper').removeClass('scrolled');
  }
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function(event){
   var st = $(this).scrollTop();
   if (st > lastScrollTop){
       // downscroll code
       $('.header_wrapper').addClass('down');
   } else {
      // upscroll code
      $('.header_wrapper').removeClass('down');
   }
   lastScrollTop = st;
});


/*Language Switcher*/
$(".language.is-active").on('click', function() {
    return false;
})

/*More text*/
$(".moretext").on('click', function() {
    $(this).parent().find('.more_text').slideToggle();
    return false;
})

// Form
$("p.open_hide_box").on('click', function() {
    $(this).parent().find('.hide_box').slideToggle();
    return false;
});

// Open Form Real Estate
$(".open_mobal_form").on('click', function() {
    var link_id=$(this).attr("data-id");
    $( ".forms_wrapper .form_modal" ).each(function( index ) {
        var form_id=$(this).attr("data-id");
        if(form_id==link_id){
          $(this).slideToggle();
        }
    });
    return false;
});

$(".form_modal button").on('click', function() {
      $(this).parent().slideToggle();
});

if($('body').hasClass('page-template-tmpl_realeastate')){
  $(".buy_rent label").on('click', function() {
      if($(this).hasClass('active')){
        $(this).removeClass('active');
        $(this).parent().find('input').val('0');
      }else{
        $(this).addClass('active');
        $(this).parent().find('input').val('1');
      }
      return false;
  });
}else if($('body').hasClass('page-template-tmpl_realeastate_overview')){

$(".real_category label").on('click', function() {
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).parent().find('input').val('0');
    }else{
      $(this).addClass('active');
      $(this).parent().find('input').val('1');
    }
    return false;
});
}else if($('body').hasClass('search-results')){
  $(".real_category label").on('click', function() {
      if($(this).hasClass('active')){
        $(this).removeClass('active');
        $(this).parent().find('input').val('0');
      }else{
        $(this).addClass('active');
        $(this).parent().find('input').val('1');
      }
      return false;
  });
}else{
  //do nothing
}



/*Open Menu*/
$(".menu_button").on('click', function() {
      if($(this).hasClass('is-active')){
          $("body").css('overflow-y','scroll');
          $(this).removeClass('is-active');
          $('.menu_button').removeClass('is-active');
          $('.headermenu').removeClass('is-active');
        }
        else{
          $(this).addClass('is-active');
          $('.menu_button').addClass('is-active');
          jQuery("body").css('overflow-y','hidden');
          $('.headermenu').addClass('is-active');
      }
});


/*Faq*/
$(".faq").on('click', function() {
    $(this).find('.hidden_content').slideToggle();
    if($(this).find('.button').hasClass('active')){
      $(this).find('.button').removeClass('active');
    }else{
      $(this).find('.button').addClass('active');
    }
    return false;
});

/*Gallery*/
          import Swiper, { Navigation, Pagination } from 'swiper';

          const gallery_swi = new Swiper(".gallery_swipe", {
            loop: true,
            spaceBetween: 200,
            grabCursor: true,
            slidesPerView:2,
            speed: 1000,
            centeredSlides: true,
            breakpoints: {
             // when window width is >= 320px
             320: {
               spaceBetween: 30,
             },
             // when window width is >= 768px
             768: {
               spaceBetween: 70,
             },
             // when window width is >= 1024px
             1024: {
               spaceBetween: 100,
             },
             // when window width is >= 1200px
             1200: {
               spaceBetween: 200,
             }
           }
          });

          const project_swipe = new Swiper(".project_swipe", {
            loop: true,
            spaceBetween:35,
            grabCursor: false,
            slidesPerView:3,
            speed:500,
            breakpoints: {
             // when window width is >= 0px
             0: {
               slidesPerView:1,
             },
             300: {
               slidesPerView:1.5,
               spaceBetween: 20,
             },
             // when window width is >= 768px
             768: {
               slidesPerView:1.5,
             },
             // when window width is >= 1024px
             1024: {
               slidesPerView:2,
             },
             // when window width is >= 1200px
             1200: {
               slidesPerView:3,
             }
           }
          });

          const studies_swipe = new Swiper(".studies_swipe", {
            loop: false,
            spaceBetween:0,
            grabCursor: true,
            speed:500,
            effect: "creative",
            creativeEffect: {
              prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
          });


$(document).ready(function(){
  $(window).scroll(function(){
    if ($(this).scrollTop() > 100) {
      $('.back-to-top').addClass('active');
    } else {
      $('.back-to-top').removeClass('active');
    }
  });
  $('.back-to-top').click(function(){
    $('html, body').animate({
      scrollTop: 0
    }, 500);
    return false;
  });
});


setTimeout(function(){
  $('.is_home_page').removeClass('is_home_page');
  $('h2.delayed').removeClass('delayed');
}, 5000);

document.querySelectorAll("#architect .read-more").forEach((readMore) => {
  readMore.addEventListener("click", () => { // Corrected event type syntax
    readMore.classList.add('hidden');
    
    const previousElement = readMore.previousElementSibling;
    if (previousElement) { // Check if previousElementSibling exists
      previousElement.classList.add('expanded');
      previousElement.style.maxHeight = previousElement.scrollHeight + 'px';
    }
  });
});
